<template>
  <div class="evaluate">
    <van-nav-bar title="放弃入职"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft" />
    <!-- 放弃入职原因 -->
    <van-cell class="mspj"
              value="放弃原因" />
    <van-field v-model="iv_pj"
               rows="5"
               class="remark"
               autosize
               type="textarea"
               placeholder="请输入"
               show-word-limit />
    <div class="footer">
      <van-button type="info"
                  @click="saveIvSetView"
                  :disabled="isDisable">确定</van-button>
    </div>
  </div>
</template>

<script>
import { dropEntry } from '@api/wxzp.js';
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { Toast } from 'vant';
export default {
  data () {
    return {
      proposal: "", // 建议
      popType: "",
      showWorkType: false,
      showResult: false,
      result: "", // 面试结果
      remark: "",
      iv_pj: "",
      userInfo,
      leftToPath: "",
      isDisable: false
    };
  },
  methods: {
    onClickLeft () {
      if (this.$route.query.returnPath == "giveUpEntry")
      {
        this.$router.push({
          path: "/inductionInfo",
          query: {
            autoid: this.$route.query.autoid,
            stationid: this.$route.query.stationid,
            footerType: "待入职"
          }
        });
      } else
      {
        this.$router.push({
          path: "/toInduction"
        });
      }

    },
    showPopup (name) {
      this.showWorkType = true;
      this.popType = name;
    },
    onResultConfirm (value) {
      this.result = value;
      this.showResult = false;
    },
    showResultPopup () {
      this.showResult = true;
    },
    onResultCancel () {
      this.showResult = false;
    },
    onConfirm (value, index) {
      this.showWorkType = false;
      if (this.popType == "评分")
      {
        this.score = value;
      } else if (this.popType == "建议")
      {
        this.proposal = value;
      }
    },
    onCancel () {
      this.showWorkType = false;
    },
    saveIvSetView () {
      if (!this.iv_pj)
      {
        Toast('放弃原因必填');
        return false;
      }
      dropEntry({
        autoid: this.$route.query.autoid,
        notentryreason: this.iv_pj,
        username: this.userInfo.username
      }).then(res => {
        if (res.iserror == 0)
        {
          Toast('修改成功！');
          this.isDisable = true;
          this.$router.push({
            path: "/inductionInfo",
            query: {
              autoid: this.$route.query.autoid,
              stationid: this.$route.query.stationid,
              footerType: "待入职"
            }
          });
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.evaluate {
  height: 100vh;
  text-align: center;
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    ::v-deep .van-nav-bar__right {
      span {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    .van-icon-ellipsis {
      color: #fff;
    }
  }
  .van-field {
    margin-bottom: 28px;
    box-sizing: border-box;
  }
  /deep/.van-cell__value {
    .van-field__body {
      input {
        text-align: right;
      }
    }
  }
  .mspj {
    padding-left: 18px;
    box-sizing: border-box;

    span::before {
      content: "*";
      color: red;
      margin-right: 4px;
    }
  }
  .footer {
    padding: 30px 0;
    background: #fff;
    .remark {
      margin-bottom: 0;
      height: calc(100% - 180px);
      box-sizing: border-box;
    }
    .van-button {
      width: 90%;
    }
  }
}
</style>
